<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.role.label')"
                    label-for="role"
                    :state="errors && errors.role ? false : null"
                  >
                    <b-form-select
                      v-model="item.role"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      :class="{ 'is-invalid': errors && errors.role }"
                      input-id="role"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.role">
                      {{ errors.role[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.gender.label')"
                    label-for="locale"
                  >
                    <v-select
                      v-model="item.gender"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="genderOptions"
                      input-id="gender"
                      :reduce="val => val.value"
                      :clearable="false"
                      :class="{ 'is-invalid': errors && errors.gender }"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.gender">
                      {{ errors.gender[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.first_name.label')"
                    label-for="first_name"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="item.first_name"
                      :state="errors && errors.first_name ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.first_name">
                      {{ errors.first_name[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.last_name.label')"
                    label-for="last_name"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="item.last_name"
                      :state="errors && errors.last_name ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.last_name">
                      {{ errors.last_name[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.middle_name.label')"
                    label-for="middle_name"
                  >
                    <b-form-input
                      id="middle_name"
                      v-model="item.middle_name"
                      :state="errors && errors.middle_name ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.middle_name">
                      {{ errors.middle_name[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.email.label')"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="item.email"
                      :state="errors && errors.email ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.email">
                      {{ errors.email[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.phone.label')"
                    label-for="phone"
                  >
                    <InputMask
                      id="phone"
                      v-model="item.phone"
                      :class="{ 'is-invalid': errors && errors.phone }"
                      mask="+38(999)999-99-99"
                      class="form-control"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.phone">
                      {{ errors.phone[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.password.label')"
                    label-for="account-password"
                  >
                    <b-form-input
                      v-model="item.password"
                      name="password"
                      :placeholder="$t('form.password.label')"
                      :state="errors && errors.password ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.password">
                      {{ errors.password[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('admin.users.tabs.addresses.label')"
              @click="loadCities"
            >
              <b-overlay rounded="sm">
                <div class="repeater-form">
                  <b-row
                    v-for="(address, index) in item.addresses"
                    :id="'address' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="$t('form.city_id.label')"
                        :label-for="'addresses.' + index + '.city_id'"
                        :state="errors && errors['addresses.' + index + '.city_id'] ? false : null"
                      >
                        <v-select
                          :id="'addresses.' + index + '.city_id'"
                          v-model="address.city_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="citiesOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'addresses.' + index + '.city_id'"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['addresses.' + index + '.city_id']">
                          {{ errors['addresses.' + index + '.city_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.street.label')"
                        :label-for="'addresses.' + index + '.street'"
                      >
                        <b-form-input
                          :id="'addresses.' + index + '.street'"
                          v-model="address.street"
                          :state="errors && errors['addresses.' + index + '.street'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['addresses.' + index + '.street']">
                          {{ errors['addresses.' + index + '.street'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.number.label')"
                        :label-for="'addresses.' + index + '.number'"
                      >
                        <b-form-input
                          :id="'addresses.' + index + '.number'"
                          v-model="address.number"
                          :state="errors && errors['addresses.' + index + '.number'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['addresses.' + index + '.number']">
                          {{ errors['addresses.' + index + '.number'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.flat.label')"
                        :label-for="'addresses.' + index + '.flat'"
                      >
                        <b-form-input
                          :id="'addresses.' + index + '.flat'"
                          v-model="address.flat"
                          :state="errors && errors['addresses.' + index + '.flat'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['addresses.' + index + '.flat']">
                          {{ errors['addresses.' + index + '.flat'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeAddress(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addAddress"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('general.add') }}</span>
                </b-button>

                <b-row
                  v-if="errors && errors.addresses"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.addresses[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'users-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required, alphaNum, email } from '@validations'
import _ from 'lodash'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      required,
      alphaNum,
      email,
      roleOptions: [],
      citiesOptions: [],
      languages: [],
      languagesSelect: [],
      item: null,
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = response.data.data
        this.languagesSelect = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http.get('/api/roles').then(response => {
      const { data } = response.data

      this.roleOptions = this.$options.filters.transformForBSelect(data, 'name', 'title')
    })

    await this.$http.get(`/api/users/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'users-index' })
        }
      })
  },
  methods: {
    loadCities() {
      if (Object.keys(this.citiesOptions).length) {
        return
      }

      this.$http.get('/api/cities')
        .then(response => {
          this.citiesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    addAddress() {
      this.item.addresses.push({
        city_id: null,
        street: null,
        number: null,
        entrance: null,
        flat: null,
        i_not_receive: false,
      })
    },
    removeAddress(index) {
      this.item.addresses.splice(index, 1)
    },
    async onSubmit() {
      const data = {
        first_name: this.item.first_name,
        last_name: this.item.last_name,
        middle_name: this.item.middle_name,
        email: this.item.email,
        phone: this.item.phone.replace(/[^0-9 ]/g, ''),
        role: this.item.role,
        addresses: this.item.addresses,
        subscriber: this.item.subscriber,
        gender: this.item.gender,
        password: this.item.password,
        slug: this.item.slug,
      }

      this.$http.put(`/api/users/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'users-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        middle_name: item.middle_name,
        email: item.email,
        phone: item.phone,
        role: item.roles[0].name,
        addresses: [],
        gender: item.gender,
        slug: item.slug,
      }

      data.addresses = _.reduce(item.addresses, (result, address) => {
        result.push({
          id: address.id,
          city_id: address.city_id,
          street: address.street,
          number: address.number,
          entrance: address.entrance,
          flat: address.flat,
          i_not_receive: address.i_not_receive,
        })
        return result
      }, [])

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
